import './App.css';

function App() {
  return (
    <div className="App">
      <div id="myShop">
        <a href="https://shop.spreadshirt.com/malachi5">Gal Malachi</a>
      </div>
    </div>
  );
}

export default App;
